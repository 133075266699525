import {Component, Input, OnInit} from '@angular/core';
import {OperationDef} from '../../core/definitions/operation-def';

@Component({
  selector: 'app-operation-step-indicator',
  templateUrl: './operation-step-indicator.component.html',
  styleUrls: ['./operation-step-indicator.component.scss']
})
export class OperationStepIndicatorComponent implements OnInit {

  constructor() { }
  @Input() currentOperation: OperationDef;

  ngOnInit(): void {
  }

}
