import {Component, OnInit} from '@angular/core';
import {PrimusRouteService} from '../../core/primus-route.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {CmsApiService} from '../../core/cms-api.service';
import {ModelsService} from '../../core/models.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CurrentObjectService} from '../../core/current-object.service';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {Selector} from '../../core/definitions/reference';
import {OperationAndSectionsContainerParams, OperationService} from '../../operations/operation.service';
import {OperationStepType} from '../../core/definitions/operation-step-type.enum';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationDialogService} from '../../operations/operation-dialog.service';
import {SuperobjectFolderItem} from '../../core/definitions/super-object-folder-item';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationExecutorType} from '../../core/definitions/operation-executor-type.enum';

export class NewModelInfo {
  selectors: Array<Selector>;
}

@Component({
  selector: 'app-object-new-page',
  templateUrl: './object-new-page.component.html',
  styleUrls: ['./object-new-page.component.scss']
})

export class ObjectNewPageComponent implements OnInit {

  object: SuperObjectModel;
  selectorContainer: SelectorContainer;
  objectType;
  debug = false;
  newObjectText = '';
  edit = true;
  objectTypeName;
  newModelInfo: NewModelInfo;
  actions = [];
  operationContainer: OperationContainer;
  // Contains menu for creating target folder for multiple objects
  createMultipleOperationContainer: OperationContainer;
  createMultiple = false;
  createMultipleCount = 1;
  createMultipleFolder: SuperObjectModel;
  maxCreateCount = 30;

  private canSelectImg;

  constructor(private primusRoute: PrimusRouteService,
              private searchSelectorService: SearchSelectorService,
              private cms: CmsApiService,
              private models: ModelsService,
              private mediaHelper: MediaHelperService,
              public currentObjectService: CurrentObjectService,
              private operationService: OperationService,
              private operationDialogService: OperationDialogService) {
  }

  ngOnInit() {
    this.objectType = this.primusRoute.params.objectType;
    this.debug = this.primusRoute.params.debug;
    this.models.getModelTitle(this.objectType).then(title => {
      this.newObjectText = title;
    });

    this.createOperationAndSectionsContainer().then();

    this.cms.getNewModelInfo({modelName: this.objectType}).then(
      data => {
        this.newModelInfo = <NewModelInfo>data;
      },
      () => {
        console.error('Unable to get new model info for ' + this.objectType);
      }
    );
  }

  canSelectImages() {
    let res = false;
    if (this.object) {
      if (this.canSelectImg === undefined && this.object) {
        this.canSelectImg = false;
        this.mediaHelper.objectCanHaveImages(this.object).then(canSelectRes => {
          this.canSelectImg = canSelectRes;
        });
      }
      res = this.canSelectImg;
    }
    return res;
  }

  enableSelector(selector) {
    this.selectorContainer = this.searchSelectorService.createSelectorContainer(this.object);
    this.searchSelectorService.enableSelector(selector, this.selectorContainer, null,
      {
        searchContainerCreated: (/*sc*/) => {
        },
        selectorCallback: (arts) => {
          this.selectorCallback(arts);
        }
      }
    );
  }

  async enableCreateMultiple() {
    this.createMultiple = !this.createMultiple;
    if (this.createMultiple) {
      this.createMultipleOperationContainer = await this.operationService.createOperationContainer(
        OperationTarget.OBJECT_NEW_PAGE, [this.object]);
      await this.operationService.setOperations(this.createMultipleOperationContainer);
      await this.setObjectMultipleProps();
    }
  }

  setMultipleCount() {
    if (this.createMultipleCount < 1) {
      this.createMultipleCount = 1;
    }
    this.object.create_multiple_count = this.createMultipleCount;
  }

  get isCopy() {
    let res = false;
    if (this.operationContainer?.currentOperation?.$$currentStep) {
      res = this.operationContainer.currentOperation.$$currentStep.$$isCopy;
    }
    return res;
  }

  async openMultipleCreationOperation(operation: OperationDef) {
    this.operationService.setCurrentOperation(this.createMultipleOperationContainer, operation);
    const operationResult = await this.operationDialogService.openOperationDialog(this.createMultipleOperationContainer);
    if (operationResult.resultObject?.object_type === 'folder') {
      this.createMultipleFolder = operationResult.resultObject;
      await this.setObjectMultipleProps();
    }
  }

  private async createOperationAndSectionsContainer(object?: SuperObjectModel) {
    const useExisting = !!object;
    this.operationContainer = await this.operationService.createOperationAndRootSectionsContainer({
      useExistingObject: useExisting,
      objectType: this.objectType,
      object: object,
      isCopy: useExisting,
      templateGroupId: this.primusRoute.params.template_group_id,
      executorType: OperationExecutorType.ARTIFACT,
      operationStepType: OperationStepType.EDIT_NEW_OBJECT,
      operationTarget: OperationTarget.NEW_OBJECT_VIEW,
      usePrimeFields: !useExisting
    } as OperationAndSectionsContainerParams);
    this.object = this.operationContainer.rootSectionsContainer.rootObject;
    this.currentObjectService.currentObject = this.object;
    this.objectTypeName = this.objectType;
    if (this.createMultiple) {
      await this.setObjectMultipleProps();
    }
  }

  private async setObjectMultipleProps() {
    this.object.create_multiple = this.createMultiple;
    this.object.create_multiple_count = this.createMultipleCount;
    if (this.object.identification_number) {
      this.object.identification_number.identification_number = '_IGNORE_';
      this.operationContainer.rootSectionsContainer.formGroup.controls[
        'identification_number.identification_number'].setValue('_IGNORE_');
    }
    if (this.createMultipleFolder) {
      const folderItem = new SuperobjectFolderItem();
      folderItem.folder_id = this.createMultipleFolder.artifact_id;
      folderItem.folder_id_value = this.createMultipleFolder.artifact_name;
      this.object.folders = [folderItem];
    }
  }

  private selectorCallback(arts) {
    if (arts && arts.length > 0) {
      this.object = null;
      this.operationContainer = null;
      this.searchSelectorService.disableSelector(this.selectorContainer);
      setTimeout(() => {
        this.object = arts[0];
        this.createOperationAndSectionsContainer(this.object).then();
      }, 500);
    }
  }

}
