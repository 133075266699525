<div class="field-select-options" [tabIndex]="0" #focusOption>
  <mat-selection-list [multiple]="false">
    <cdk-virtual-scroll-viewport itemSize="26" #scrollViewport
                                 [style.height]="viewportHeight"
                                 minBufferPx="65"
                                 maxBufferPx="260"
                                 class="field-select-options__content">
      <mat-list-option [tabIndex]="i === selectedRow ? 0 : -1"
                       [ngStyle]="setStyle(i)"
                       (click)="selectOption($event, option)"
                       class="field-select-options__option-item"
                       *cdkVirtualFor="let option of dataSource; let i = index; let count = count">
        <span class="alignChildren">
          <mat-checkbox style="padding-right: 10px" *ngIf="isArray"
                        [checked]="this.selectedMap.has(option.artifact_id)"></mat-checkbox>
          <span [innerHTML]='markQuery(option[displayField])'></span>
          <span [ngClass]="{'concept-authority-right': option.authority_id}"></span>
        </span>
      </mat-list-option>

      <!-- Feedback text, if no options available or no search result -->
      <ng-container *ngIf="!dataSource.isLoading()">
        <div *ngIf="(dataSource.asObservable() | async)?.length === 0 && !query" class="user-feedback">
          <span class="user-feedback-text" translate="TRANS__FIELD_SELECT_OPTIONS__NO_AVAILABLE_OPTIONS"></span>
        </div>
        <div *ngIf="(dataSource.asObservable() | async)?.length === 0 && query" class="user-feedback">
          <span class="user-feedback-text" translate="TRANS__FIELD_SELECT_OPTIONS__NO_SEARCH_RESULT"></span>
        </div>
      </ng-container>

      <div *ngIf="dataSource.isLoading()" class="spinner-wrapper">
        <mat-spinner color="accent" diameter="16"></mat-spinner>
        <span style="padding-left: 5px" translate="TRANS__SEARCH__LOADING_INFO"></span>
      </div>

    </cdk-virtual-scroll-viewport>
  </mat-selection-list>
</div>

