import {SearchObject} from './search-object';
import {CultureHubParams} from './culture-hub-params';

export class Reference {
  object_type: string;
  meta_type: any;
  display_field: string;
  node_display_field: string;
  sort: string;
  ref_filter: ReferenceFilter[];
  title: string;
  ref_prop: string;
  get_existing_items_from_search: boolean;
  ref_delete_disabled: boolean;
  label_prop: string;
  is_hierarchic: boolean;
  hierarchic_parent_select_forbidden: boolean;
  hierarchic_display_list_view: boolean;
  parents_only: boolean;
  parent_field: string;
  parent_target_field: string;
  can_edit_existing: boolean;
  can_edit_context: boolean;
  add_new_params: AddNewParams;
  selector: Selector;
  no_clear: boolean;
  set_others: Array<SetOther>;
  compare_value_field: string;
  search_display_limit: number;
  search_kulturnav: boolean;
  kulturnav_params: CultureHubParams;
  operation_field: string;
  field_operation_type: string;
  show_usage: boolean;
  group_search: boolean;
  group_field: string;
  get_object_type_from_superobject_type_id: boolean;
  write_collections_only: boolean;
  reference_id: string;
}

export interface ReferenceFilter {
  filter_field: string;
  values: Array<string>;
  fq: string;
}

export class AddNewParams {
  add_button_title: string;
  copy_fields: Array<CopyField>;
  full_object_edit: boolean;
  name_field_path: string;
  new_object_type: string;
  can_add_new: boolean;
}

export class CopyField {
  copy_source_type: string;
  copy_source_field: string;
  copy_target_field: string;
  copy_default_value: any;
  copy_transform_type: string;
}

export class Selector {
  view: string;
  selector_type: string;
  action: string;
  selector_button_hidden: boolean;
  selector_button_text: string;
  select_button_text: string;
  filters: any;
  checked_filters: any;
  context_restrict: boolean;
  context_restrict_field: string;
  item_removed_property: string;

  // Client specific fields
  selected: Array<SearchObject> = [];
  used: Array<any>;
  restrictions: Array<any>;
  query: string;

}

export class SetOther {
  source_field: string;
  target_field: string;
}
