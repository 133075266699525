import {
  Component, ElementRef,
  EventEmitter,
  Input, OnChanges, OnDestroy, OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {VirtualScrollEditDataSourceList} from '../../virtual-scroll-edit-data-source-list';
import {Observable, Subscription} from 'rxjs';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Reference} from "../../../core/definitions/reference";


@Component({
  selector: 'app-field-select-options',
  templateUrl: './edit-field-select-options.component.html',
  styleUrls: ['./edit-field-select-options.component.scss']
})
export class EditFieldSelectOptionsComponent implements OnInit, OnChanges, OnDestroy {
  viewportHeight = '182px';
  subscription = new Subscription();
  displayField: string;
  selectedMap = new Map();

  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() query;
  @Input() selectedRow;
  @Input() isArray;
  @Input() fullSearch;
  @Input() dataSource: VirtualScrollEditDataSourceList;
  @Input() keyPressed: Observable<any>;
  @Output() optionSelected = new EventEmitter<object>();
  @Output() optionUnChecked = new EventEmitter<object>();
  @Output() setInputFocus = new EventEmitter<object>();
  @Output() keyEventsInShowOptions = new EventEmitter<object>();

  @ViewChild('focusOption', {static: true}) focusOption: ElementRef;
  @ViewChild('scrollViewport') virtualScroll: CdkVirtualScrollViewport;

  ngOnInit() {
    this.initSelectOptionData().then();
  }

  /**
   * Select option on click
   * @param event click event
   * @param option element chosen
   */
  selectOption(event, option) {
    const found = this.findClassName(event.target, 'ignoreClicks-' + this.fieldParameters.field.name);
    if (!found) {
      event.preventDefault();
      this.emit(option);
    }
  }

  private async initSelectOptionData() {
    this.displayField = this.reference.display_field;
    // Listen and react to keyPresses from parent
    this.subscription.add(
      this.keyPressed.subscribe((key) => {
        this.selectOptionWithKey(key);
      })
    );
    // Updates viewport height when list length changes
    this.subscription.add(
      this.dataSource.asObservable()
        .subscribe((data) => {
          this.viewportHeight = (data.length * 26) + 'px'; // scss controls min (80px) and max (182px) height
        })
    );
    // Fill selectedMap with selected options with _destroy = false
    if (
      Array.isArray(this.fieldParameters.object[this.fieldParameters.field.name]) &&
      this.fieldParameters.object[this.fieldParameters.field.name].length > 0)
    {
      const fieldName = this.fieldParameters.field.inline.unique_props[0];
      this.fieldParameters.object[this.fieldParameters.field.name].forEach((option) => {
        if (!option._destroy) {
          this.selectedMap.set(option.$$orig[fieldName], true)
        }
      })
    }

  }

  private findClassName(nodeIn, className) {
    let found = false;
    if (this.hasClass(nodeIn, className)) {
      found = true;
    }
    return found;
  }

  private hasClass(el, class_to_match) {
    if (el && el.className &&
      typeof class_to_match === 'string') {
      let c = el.getAttribute('class');
      c = ' ' + c + ' ';
      return c.indexOf(' ' + class_to_match + ' ') > -1;
    } else {
      return false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // Query and scroll to the beginning
    if (changes.query?.previousValue !== changes.query?.currentValue) {
      if (changes.query.firstChange) {
        this.dataSource.searchByQuery(null);
      } else {
        this.dataSource.searchByQuery(this.query);
      }
      this.selectedRow = 0;
      this.virtualScroll?.scrollToIndex(this.selectedRow);
    }
  }

  /**
   * Set highlight on current row, used for marking position with navigation
   * @param i index number
   */
  setStyle(i: number) {
    if (this.selectedRow === i) {
      return {'background': '#e1f5fe'};
    }
  }

  /**
   * Navigate list and select item with key
   * @param key pressed
   */
  selectOptionWithKey(key: string): void {
    if (key === 'ArrowDown') {
      if (this.selectedRow + 1 < this.virtualScroll.getDataLength()) {
        this.selectedRow += 1;
        this.virtualScroll.scrollToIndex(this.selectedRow);
      }
    } else if (key === 'ArrowUp') {
      if (this.selectedRow - 1 >= 0) {
        this.selectedRow -= 1;
        this.virtualScroll.scrollToIndex(this.selectedRow);
      }
    } else if (key === 'Enter') {
      const option = this.dataSource.getIndex(this.selectedRow);
      this.emit(option);
    }

  }

  /**
   * Emits selected option and update selectedMap
   * @param option
   */
  emit(option) {
    if (!this.selectedMap.has(option.artifact_id)) {
      this.optionSelected.emit(option);
      this.selectedMap.set(option.artifact_id, true);
    } else {
      this.optionUnChecked.emit(option);
      this.selectedMap.delete(option.artifact_id);
    }
    this.dataSource.searchByQuery(null);
  }

  /**
   * Method is uses query to mark matching substrings in bold
   * @param value string value to match
   * @return value with matching substring in bold
   */
  markQuery(value: string): string {
    try {
      const match: RegExpMatchArray = value.toLowerCase().match(this.query?.toLowerCase());
      const matchValue = match[0];
      const matchIndex = match.index;
      return (value.substring(0, matchIndex)
        + '<b>' + value.substring(matchIndex, matchIndex + matchValue.length) + '</b>'
        + value.substring(matchIndex + matchValue.length));
    } catch (e) {
      return value;
    }
  }

  // Unsubscribe on destroy
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
