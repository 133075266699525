import {UploadInfo} from './upload-info';
import {SuperObjectModel} from './super-object-model';
import {SectionsContainer} from './sections-container';

export class UploadMediaContainer {
  uploadInfo: UploadInfo;
  files: Array<SuperObjectModel> = [];
  fileObjects: Array<any> = [];
  parentObject: SuperObjectModel;
  name: String;
  fileObjectSectionsContainers: SectionsContainer[] = [];
}

