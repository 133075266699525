import {Injectable} from '@angular/core';
import {ModelSectionsService} from '../core/model-sections.service';
import {UiToolsService} from '../core/ui-tools.service';
import {SectionsContainer} from '../core/definitions/sections-container';
import {SuperObjectModel} from '../core/definitions/super-object-model';
import {ToolbarAction} from '../core/definitions/toolbar-action';
import {OperationActionType} from '../core/definitions/operation-action-type.enum';
import {OperationService} from './operation.service';
import {OperationStepType} from '../core/definitions/operation-step-type.enum';
import {OperationContainer} from '../core/definitions/operation-container';
import {OperationStepExecutionResult} from '../core/definitions/operation-step-execution-result';
import {LoggerService} from "../core/logger.service";

export interface RunActionResult {
  registerHasBeenRun: boolean;
  operationStepExecutionResult: OperationStepExecutionResult;
}

@Injectable({
  providedIn: 'root'
})
export class OperationToolbarServiceService {

  private invalidObjectReason = 'TRANS__OPERATION__DISABLED_REASON__INVALID_OBJECT';
  private objectInUseReason = 'TRANS__DELETE_OBJECT__DISABLE_REASON__OBJECTS_IN_USE';
  private noValidContextObjectsReason = 'TRANS__OPERATION__DISABLE_REASON__NO_VALID_CONTEXT_OBJECTS';

  constructor(private modelSectionsService: ModelSectionsService,
              private uiToolsService: UiToolsService,
              private operationService: OperationService,
              private loggerService: LoggerService) {
  }

  getSectionsContainers(operationContainer: OperationContainer): SectionsContainer[] {
    return this.operationService.getCurrentOperationSectionContainers(operationContainer);
  }

  getOperationObjects(operationContainer: OperationContainer): SuperObjectModel[] {
    return this.getSectionsContainers(operationContainer).map(sectionsContainer => sectionsContainer.rootObject);
  }

  getIsInvalid(operationContainer: OperationContainer, registerHasBeenRun: boolean): boolean {
    let res = false;
    if (registerHasBeenRun) {
      const sectionsContainers = this.getSectionsContainers(operationContainer);
      if (sectionsContainers) {
        for (let t = 0; t < sectionsContainers.length; t++) {
          if (sectionsContainers[t].formGroup?.invalid) {
            res = true;
            break;
          }
        }
      } else {
        res = true;
      }
    }
    return res;
  }

  getTooltipText(selectorEnabled, action) {
    if (action.$$disabledReason) {
      return action.$$disabledReason;
    }
    if (selectorEnabled && action.helper_box_text_open_selector) {
      return action.helper_box_text_open_selector;
    }
    if (!selectorEnabled && action.helper_box_text) {
      return action.helper_box_text;
    }
  }

  isDisabled(operationContainer: OperationContainer, action: ToolbarAction, registerHasBeenRun: boolean): boolean {
    let res = false;
    const sectionsContainers = this.getSectionsContainers(operationContainer);
    switch (operationContainer.currentOperation.$$currentStep.operation_step_type) {
      case OperationStepType.EDIT_OBJECT:
      case OperationStepType.CONTINUE_NOT_SUBMITTED_OBJECT_GET_OPERATION_CONTEXTS:
      case OperationStepType.CREATE_OBJECT:
        res = this.isDisabledForEditObject(operationContainer, sectionsContainers, action, registerHasBeenRun);
        break;
      case OperationStepType.EDIT_NEW_OBJECT:
      case OperationStepType.DELETE_FROM_OBJECT:
      case OperationStepType.SELECT_MODEL_FOR_NEXT_STEP:
      case OperationStepType.CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP:
      case OperationStepType.CONTINUE_NOT_SUBMITTED_OBJECT:
      case OperationStepType.CONTINUE_SUBMITTED_OBJECT:
      case OperationStepType.CHANGE_STATE:
      case OperationStepType.COPY_OBJECT:
        // Do nothing for now
        break;
      case OperationStepType.UPLOAD_MEDIA:
        res = !sectionsContainers?.length;
        break;
      case OperationStepType.DELETE_OBJECT:
        // TODO: The below test may be used on more operation step types
        const sectionsContainer = sectionsContainers ? sectionsContainers[0] : null;
        if (sectionsContainer && sectionsContainer.operationContextObjects) {
          res = !sectionsContainer.operationContextObjects.length;
          // Disable register button if any objects are in use in other objects
          if (!res && sectionsContainer.rootObject.object_usage.length) {
            const hasUsage = sectionsContainer.rootObject.object_usage.find(usage => usage.objectsUsing.length);
            res = !!hasUsage;
            action.$$disabledReason = this.objectInUseReason;
          } else if (action.$$disabledReason === this.objectInUseReason) {
            action.$$disabledReason = '';
          }
        }
        break;
      default:
        this.loggerService.info(`No disable check implemented for ${operationContainer.currentOperation.$$currentStep.operation_step_type}`)
    }
    return res;
  }

  getErrorCount(operationContainer: OperationContainer) {
    let errCount = 0;
    const sectionsContainers = this.getSectionsContainers(operationContainer);
    if (sectionsContainers) {
      sectionsContainers.forEach(sectionsContainer => {
        errCount += this.modelSectionsService.getSectionErrors(sectionsContainer).length;
      });

    }
    return errCount;
  }

  nextError(operationContainer: OperationContainer) {
    // TODO: Rewrite in order to support multiple sections!!!
    const errorId = this.modelSectionsService.nextError(this.getSectionsContainers(operationContainer)[0]);
    if (!this.uiToolsService.scrollTo(errorId)) {
      this.uiToolsService.scrollToOpenArrayItem(errorId, 0, this.getOperationObjects(operationContainer)[0]);
    }
  }

  clearScrollTimeout() {
    this.uiToolsService.clearScrollToArrayItemTimeout();
  }

  async runAction(operationContainer: OperationContainer,
                  action: ToolbarAction,
                  useCmsErrorHandler: boolean,
                  lastOperationStepExecutionResult?: OperationStepExecutionResult,
  ): Promise<RunActionResult> {
    let registerHasBeenRun = false;
    let operationStepExecutionResult: OperationStepExecutionResult;
    switch (action.action_type) {
      case OperationActionType.REGISTER:
      case OperationActionType.DELETE:
      case OperationActionType.REGISTER_STAY_ON_STEP:
        operationStepExecutionResult = await this.executeOperation(operationContainer, action, useCmsErrorHandler);
        registerHasBeenRun = true;
        break;
      case OperationActionType.CANCEL_REGISTER:
        await this.operationService.cancelOperationStep(operationContainer, action);
        break;
      case OperationActionType.NEXT_STEP:
        this.operationService.goNextOperationStep(operationContainer);
        break;
      case OperationActionType.PREVIOUS_STEP:
        this.operationService.goPrevOperationStep(operationContainer);
        break;
      case OperationActionType.CHANGE_STATE:
        await this.operationService.changeState(operationContainer.currentOperation.$$currentStep, lastOperationStepExecutionResult);
        await this.operationService.cancelOperationStep(operationContainer, action);
        break;
      default:
        console.warn('Unable to handle action type ' + action.action_type);
    }
    return {
      operationStepExecutionResult: operationStepExecutionResult,
      registerHasBeenRun: registerHasBeenRun
    };
  }

  private async executeOperation(operationContainer: OperationContainer,
                                 action: ToolbarAction,
                                 useCmsErrorHandler: boolean): Promise<OperationStepExecutionResult> {
    let canRun = true;
    let res: OperationStepExecutionResult;
    const sectionsContainers = this.getSectionsContainers(operationContainer);
    if (sectionsContainers) {
      sectionsContainers.forEach(sectionsContainer => {
        sectionsContainer.submitted = true;
        if (sectionsContainer.formGroup && sectionsContainer.formGroup.invalid) {
          canRun = false;
        }
      });
    }
    if (canRun) {
      // Execute operation towards server
      res = await this.operationService.executeOperationStep(operationContainer, action, useCmsErrorHandler);
    }
    return res;
  }

  private isDisabledForEditObject(operationContainer: OperationContainer,
                                  sectionsContainers: SectionsContainer[],
                                  action: ToolbarAction,
                                  registerHasBeenRun: boolean): boolean {
    let res = false;
    if (action.action_type === OperationActionType.REGISTER ||
      action.action_type === OperationActionType.REGISTER_STAY_ON_STEP ||
      action.action_type === OperationActionType.NEXT_STEP) {
      if (!sectionsContainers) {
        return true;
      }
      if (sectionsContainers[0].rootObject.operation_contexts_restricted?.length) {
        if (!sectionsContainers[0].rootObject.operation_contexts?.length) {
          res = true;
          action.$$disabledReason = this.noValidContextObjectsReason;
        } else {
          action.$$disabledReason = '';
        }
      }
      if (res) {
        return res;
      }
      if (!operationContainer.currentOperation.$$currentStep.skip_dirty_check) {
        const formGroups = sectionsContainers.map(sectionsContainer => sectionsContainer.formGroup);
        for (let t = 0; t < formGroups.length; t++) {
          const formGroup = formGroups[t];
          if (formGroup) {
            res = !formGroup.dirty;
            if (!res) {
              if (action.action_type === OperationActionType.REGISTER ||
                action.action_type === OperationActionType.REGISTER_STAY_ON_STEP) {
                res = registerHasBeenRun && formGroup.invalid;
              } else {
                res = formGroup.invalid;
              }
              if (res) {
                action.$$disabledReason = this.invalidObjectReason;
              } else if (action.$$disabledReason === this.invalidObjectReason) {
                action.$$disabledReason = '';
              }
            }
          }
          if (res) {
            break;
          }
        }
      }
      if (!res) {
        const operationObjects = this.getOperationObjects(operationContainer);
        for (let t = 0; t < operationObjects.length; t++) {
          if (operationObjects[t].$$annotateImage) {
            res = true;
            break;
          }
        }
      }
    }
    return res;
  }

  /**
   * Return boolean value representing if its completed or not.
   * @param operationContainer
   */
  isCompleted(operationContainer: OperationContainer) {
    switch (operationContainer.currentOperation.$$currentStep.operation_step_type) {
      case OperationStepType.CHANGE_STATE:
        return true;
      default:
        return false;
    }
  }
}
